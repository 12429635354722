import React from 'react';
import { Link } from 'react-router-dom';

import './navbar.css';

function Navbar(){

    return <nav className="navbar fixed-top navbar-expand-md navbar-dark">
    <div className='container-fluid'>
            <a className="navbar-brand" href="/#index.html"> 
              <img src="/Images/menu.png" alt="primecafe" height="100"/>
            </a>
    </div>
 </nav>;
}

export default Navbar;
