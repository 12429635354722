import React, { useState }  from 'react';
import { Link } from 'react-router-dom';
import './key42.css';

import NavbarKey from '../Components/NavbarKey/navbarkey.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Key42(){
    return <div>
      <NavbarKey/>
      <section id="banner">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <img src="/Images/foto1.png"/>
                </div>

                <div className="col-lg-6">
                    <p/>
                    <p/>
                    <h2>Não temos medo de arriscar.</h2>
                    <p/>
                    <p/>
                    <h4>Estamos em constante evolução: o seu sucesso é a nossa satisfação!</h4>
                    <p/>
                    <p/>
                    <h4></h4>
                </div>
            </div>
         </div>
       </section>

 <section id="footer">    
     <div className="container" id="linha">
             <div className="row">

             <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-heart fa-5x"></i>
                    <h3>Fidelidade</h3>                    
                    <p>Amamos o que fazemos: nos pautamos na experiência dos nossos clientes, cuidando de cada um deles com respeito e consideração.</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-globe-americas fa-5x"></i>
                    <h3>Ecossistema</h3>
                    <p>Agenda 2030 para o Desenvolvimento Sustentável: por meio da alta tecnologia as empresas estão inseridas neste cenário de transformação, utilizando as nossas criações tecnológicas sustentáveis. Preservamos hoje para as gerações de amanhã.</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-columns fa-5x"></i>
                    <h3>Multi-plataforma</h3>
                    <p>Soluções híbridas, consistentes e inovadoras: operações ágeis constroem flexibilidade e resiliência, na modalidade ideal para o seu negócio.</p>
                </div>

             </div>
      </div>
</section>

       <section id="planos">
        <div className="container">
          <div className="row">

            <div className="col-lg-4">
            <div className="card text-black bg-light mb-3">
                <img className="card-img-top" src="/Images/standard1.png" alt="key 42"/>
                <div className="card-body">
                       <h5 className="card-title text-center fs-1">Standard</h5>
                       <p className="card-text text-center fs-5">Seja seu próprio gestor. Ideal para centralizadores!</p>
                       <p className="card-text text-center fs-5">R$ 129,90/ mês</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Ordem de serviços</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Envio via whatsapp</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Banco local</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Suporte 24/7</p>
                       <p className="text-center" id="zap">
                       <a href="https://api.whatsapp.com/send?phone=5511950597167&text=Quero mais informações sobre o plano Standard%0AAguardo retorno.">
                       <p className="card-text text-center fs-5">Mais informações...</p>
                       <p className="fa fa-whatsapp fa-2x"/>
                       </a>
                       </p>
                 </div>
               </div>
            </div>


            <div className="col-lg-4">
               <div className="card text-black bg-light mb-3">
               <img className="card-img-top" src="/Images/medium1.png" alt="key 42"/>
                <div className="card-body">
                       <h5 className="card-title text-center fs-1">Developer</h5>
                       <p className="card-text text-center fs-5">Seja seu próprio gestor. Ideal para centralizadores!</p>
                       <p className="card-text text-center fs-5">R$ 179,90/ mês</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Estoque e Ordem de Serviço</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Emissão em pdf</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Banco local</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Integração com equipe</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Suporte 24/7</p>
                       <p className="text-center" id="zap">
                       <a href="https://api.whatsapp.com/send?phone=5511950597167&text=Quero mais informações sobre o plano Developer%0AAguardo retorno.">
                       <p className="card-text text-center fs-5">Mais informações...</p>
                       <p className="fa fa-whatsapp fa-2x"/>
                       </a>
                       </p>
                 </div>
               </div>
            </div>

            <div className="col-lg-4">
            <div className="card text-black bg-light mb-3">
              <img className="card-img-top" src="/Images/premium.png" alt="key 42"/>
                <div className="card-body">
                       <h5 className="card-title text-center fs-1">Premium</h5>
                       <p className="card-text text-center fs-5">Seja seu próprio gestor. Ideal para centralizadores!</p>
                       <p className="card-text text-center fs-5">R$ 349,90/ mês</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Painel de controle e Mobile</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Emissão em pdf</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Banco nuvem</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Chatbot</p>
                       <p className="card-text text-left fs-5"><p className="fa fa-check"/> Suporte 24/7</p>
                       <p className="text-center" id="zap">
                       <a href="https://api.whatsapp.com/send?phone=5511950597167&text=Quero mais informações sobre o plano Premium%0AAguardo retorno.">
                       <p className="card-text text-center fs-5">Mais informações...</p>
                       <p className="fa fa-whatsapp fa-2x"/>
                       </a>
                       </p>
                 </div>
               </div>
            </div>

          </div>

         </div>
       </section>
           <Complace/>
    </div>
  }

export default Key42;