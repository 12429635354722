import React, { useState, useEffect } from 'react';
import './nossahistoria.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Footer from '../Components/Footer/footer.jsx';
import Complace from '../Components/Complace/complace.jsx';
function Nossahistoria(){


    return <div>
      <Navbar/>
      <div className='container-fluid titulo'>
      <h1>Nossa História</h1>

        <div className='row' id="img_center">
          <div className='col-3'>
            <img src="/Images/logo.png" alt="key 42" height="100"/>
          </div>

          <div className='col-3'>
          <img src="/Images/logo.png" alt="key 42" height="100"/>
          </div>

          <div className='col-3'>
          <img src="/Images/logo.png" alt="key 42" height="100"/>
          </div>

          <div className='col-3'>
          <img src="/Images/logo.png" alt="key 42" height="100"/>
          </div>
        </div>
      </div>
       <Footer/>
       <Complace/>
    </div>
  }
    
  
export default Nossahistoria;