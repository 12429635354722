import React from 'react';
import { Link } from 'react-router-dom';
import './transparencia.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Transparencia(){
    return <div>
      <Navbar/>
      <section id="comp">
        <div className="container">
        <p className='fs-1 fw-bold text-center'>TRANSPARÊNCIA - OUVIDORIA</p> 

          <p id="justificar">
           <p className='fw-normal'> Em desenvolvimento.</p>
          </p>
           <Link to="/app/transparenciacomp"><a></a></Link>

        </div>
       </section>

       <div className="row" id="planos">

       </div>
       <Complace/>
    </div>
  }

export default Transparencia;