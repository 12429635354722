import React from 'react';
import './transparenciacomp.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function TransparenciaComp(){
    return <div>
      <Navbar/>
      <section id="comp">
        <div className="container">
        <p className='fs-1 fw-bold text-center'>TRANSPARÊNCIA - OUVIDORIA</p> 
          <p id="justificar">
          <p className='fw-normal'> Você está no Canal de Integridade da Editora Alphaville, destinado aos nossos colaboradores, clientes, fornecedores e público em geral. Por aqui é possível entrar em contato com o nosso Departamento de Compliance para comunicar, de forma segura, situações que não estejam de acordo com normas e políticas internas da empresa, bem como infrações às leis e regulamentações vigentes.</p>
          <p className='fw-normal'> É conosco que você pode conversar sobre:</p>
          <p className='fw-normal'> 1.	 Possíveis infrações às Políticas institucionais da Editora Alphaville, bem como à Missão, Visão e Valores institucionais que apregoamos e integramos; </p>
          <p className='fw-normal'> 2.	Abordagens indevidas (de colaboradores, clientes ou fornecedores);</p>
          <p className='fw-normal'> 3.	Brindes e presentes que possam causar dúvidas a respeito da independência das partes suspeitas de envolvimento com atos de corrupção, assédios moral, psicológico e sexual, discriminações, conflito de interesses, quebra do sigilo de informações, fraudes entre outros.</p>
          <p className='fw-normal'> Você não precisa se identificar para entrar em contato conosco, considerando que todas as manifestações serão recebidas de forma imparcial, garantindo seu sigilo - como dados de IP - e encaminhamento adequado. Nos mesmos moldes, você também poderá entrar em contato conosco pelo e-mail diretoria@editoraalphaville.com.br . </p>


          </p>
          
        </div>
       </section>

       <div className="row" id="planos">

       </div>
       <Complace/>
    </div>
  }

export default TransparenciaComp;