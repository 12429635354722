import React, { useState }  from 'react';
import { Link } from 'react-router-dom';
import './home.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Home(){
    return <div>
      <Navbar/>
      <section id="banner">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <img src="Images/logo.png"/>
                </div>

            </div>
         </div>
       </section>

       <section id="planos">
        <div className="container">
          <div className="row">

            <div className="col-lg-4">
            <div className="card text-black bg-light mb-3">
                <img className="card-img-top" src="Images/img_01.jpg" alt="primecafe"/>
                <div className="card-body">
                       <h5 className="card-title"></h5>
                       <p className="card-text"></p>
                       <Link to="/#"><a></a></Link>
                 </div>
               </div>
            </div>


            <div className="col-lg-4">
            <div className="card text-black bg-light mb-3">
                <img className="card-img-top" src="Images/img_03.jpg" alt="primecafe"/>
                <div className="card-body">
                       <h5 className="card-title"></h5>
                       <p className="card-text"></p>
                       <Link to="/#"><a></a></Link>
                 </div>
               </div>
            </div>

            <div className="col-lg-4">
            <div className="card text-black bg-light mb-3">
                <img className="card-img-top" src="Images/todas_embalagens.jpg" alt="primecafe"/>
                <div className="card-body">
                       <h5 className="card-title"></h5>
                       <p className="card-text"></p>
                       <Link to="/#"><a></a></Link>
                 </div>
               </div>
            </div>

          </div>

         </div>
       </section>
           <Complace/>
    </div>
  }

export default Home;