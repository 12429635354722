import React from 'react';
import { Link } from 'react-router-dom';
import './complace.css';


function Complace(){
  var ano = new Date().getFullYear(); 

  return <section id="complace">    
     <div className="container" id="linha">
     <div className="row" id="info">

<div className="col-lg-3 feature-box" id="logo">
      
   </div>

   <div className="col-lg-3 feature-box" id="col-1">
       <a><h5>Navegue</h5></a>
       <p/>
       <p><Link to="/app/sobre"><a>Sobre</a></Link></p>
       <p><Link to="/app/oquefazemos"><a>O que fazemos</a></Link></p>
   </div>

   <div className="col-lg-3 feature-box" id="col-2">
       <a><h5>Contato</h5></a>
       <p/>
       <p><Link to="/app/ouvidoria"><a>Ouvidoria</a></Link></p>
       <p><Link to="/app/trabalheconosco"><a>Trabalhe conosco</a></Link></p>
   </div>

   <div className="col-lg-3 feature-box" id="col-3">
       <a><h5>Políticas</h5></a>
       <p/>
       <p><Link to="/app/compliance"><a>Compliance</a></Link></p>
       <p><Link to="/app/privacidade"><a>Privacidade</a></Link></p>
       <p><Link to="/app/intelectual"><a>Propriedade intelectual</a></Link></p>
       <p><Link to="/app/termodeuso"><a>Termo de uso</a></Link></p>
       <p><Link to="/app/transparencia"><a>Transparência</a></Link></p>
   </div>

   <p/>

            <ul className="list-unstyled list-inline social text-center">
                <li className="list-inline-item"><a href="https://api.whatsapp.com/send?phone=5511992635710" target="_blank"><i class='fa fa-whatsapp fa-2x' id="zap"></i></a></li>						
                <li className="list-inline-item"><a href="https://www.instagram.com/primecafespresso/"><i className="fa fa-instagram fa-2x" id="insta"></i></a></li>						
                <li className="list-inline-item"><a href="https://www.facebook.com/primecafeexpresso/"><i className="fa fa-facebook fa-2x" id="face"></i></a></li>						
                <li className="list-inline-item"><a href="mailto:sac@primecafe.com.br" target="_blank"><i className="fa fa-envelope fa-2x"id="email"></i></a></li>
            </ul>

   <p>________________________________________________________________________</p>
   <p className="h6">© Desenvolvido por Mobile Now - {ano}</p>

</div>
      </div>
</section>

}

export default Complace;