import React from 'react';
import { Link } from 'react-router-dom';

import './navbarkey.css';

function NavbarKey(){

    return <nav className="navbar fixed-top navbar-expand-md navbar-dark">
    <div className='container-fluid'>
            <a className="navbar-brand" href="/#index.html"> 
              <img src="/Images/logo1.png" alt="key 42" height="100"/>
            </a>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <Link to="/" className="nav-link" aria-current="page" >Quem somos</Link>
                    </li>

                </ul>
            </div>
    </div>
 </nav>;
}

export default NavbarKey;
