import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './cliente.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Cliente(){


    return <div>
      <Navbar/>

      <section id="planos">
        <div className="container">
          <div className="row">

            <div className="col-lg-4">
               <div className="card text-white bg-dark mb-3">
               <img className="card-img-top" src="/Images/saintnemet.png" alt="key 42"/>
                  <div className="card-body">
                      <h5 className="card-title">Publicações</h5>
                      <p className="card-text">Temos o modelo ideal para ampliar os limites de suas obras.</p>
                  </div>
               </div>
            </div>


            <div className="col-lg-4">
               <div className="card text-white bg-dark mb-3">
               <img className="card-img-top" src="/Images/inovacao_2.jpg" alt="key 42"/>
                  <div className="card-body">
                      <h5 className="card-title">Tecnologia e Inovações</h5>
                      <p className="card-text">Amplie seu atendimento de forma rápida e acertiva. Respostas diretas e sem perder tempo.</p>
                  </div>
               </div>
            </div>

            <div className="col-lg-4">
               <div className="card text-white bg-dark mb-3">
               <img className="card-img-top" src="/Images/vistoria.jpg" alt="key 42"/>
                  <div className="card-body">
                      <h5 className="card-title">Vistoria e Laudo Imobiliário</h5>
                      <p className="card-text">Tenha a sua disposição nosso time de especialistas sempre para te orientar e ajudar. </p>
                  </div>
               </div>
            </div>

          </div>

         </div>
       </section>


      <Complace/>
    </div>
  }
    
  
export default Cliente;