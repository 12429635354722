import React  from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

/* Paginas */
import Home from './app/Home/home.jsx';


import Cliente from './app/Cliente/cliente.jsx';

import Nossahistoria from './app/Nossahistoria/nossahistoria.jsx';
import Solucao from './app/Solucao/solucao.jsx';
import Compliance from './app/Compliance/compliance.jsx';
import ComplianceComp from './app/ComplianceComp/compliancecomp.jsx';
import Privacidade from './app/Privacidade/privacidade.jsx';
import PrivacidadeComp from './app/PrivacidadeComp/privacidadecomp.jsx';
import Intelectual from './app/Intelectual/intelectual.jsx';
import IntelectualComp from './app/IntelectualComp/intelectualcomp.jsx';
import Key42 from './app/Key42/key42.jsx';
import Termodeuso from './app/Termodeuso/termodeuso.jsx';
import TermodeusoComp from './app/TermodeusoComp/termodeusocomp.jsx';
import Transparencia from './app/Transparencia/transparencia.jsx';
import TransparenciaComp from './app/TransparenciaComp/transparenciacomp.jsx';
import Respsocial from './app/RespSocial/respsocial.jsx';
import RespSocialComp from './app/RespSocialComp/respsocialcomp.jsx';




function App(){

return <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/app/cliente' component={Cliente} />
        <Route exact path='/app/compliance/' component={Compliance} />
        <Route exact path='/app/compliancecomp/' component={ComplianceComp} />
        <Route exact path='/app/intelectual/' component={Intelectual} />
        <Route exact path='/app/intelectualcomp' component={IntelectualComp} />    
        <Route exact path='/app/key42/' component={Key42} />
        <Route exact path='/app/nossahistoria/' component={Nossahistoria} />
        <Route exact path='/app/privacidade' component={Privacidade} />    
        <Route exact path='/app/privacidadecomp' component={PrivacidadeComp} />    
        <Route exact path='/app/solucao/' component={Solucao} />
        <Route exact path='/app/respsocial' component={Respsocial} />    
        <Route exact path='/app/respsocialcomp' component={RespSocialComp} />    
        <Route exact path='/app/termodeuso' component={Termodeuso} />    
        <Route exact path='/app/termodeusocomp' component={TermodeusoComp} />    
        <Route exact path='/app/transparencia' component={Transparencia} />    
        <Route exact path='/app/transparenciacomp' component={TransparenciaComp} />    
      </Switch>     
    </BrowserRouter>;
  }
 
export default App;