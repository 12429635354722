import React from 'react';
import { Link } from 'react-router-dom';
import './privacidade.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Privacidade(){
    return <div>
      <Navbar/>
      <section id="comp">
        <div className="container">
          <p id="justificar"> </p>
          <p className='fs-1 fw-bold text-center'>POLÍTICA DE PRIVACIDAE</p> 

          <p className='fw-bold' id="justificar"> 1.	Lei Geral de Proteção de Dados - LGPD<p/>

           <p className='fw-normal'>Em desenvolvimento.</p>
          </p>
          <Link to="/app/privacidadecomp"><a></a></Link>
        </div>
       </section>

       <div className="row" id="planos">

       </div>
       <Complace/>
    </div>
  }

export default Privacidade;