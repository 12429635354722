import React from 'react';
import './compliancecomp.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function ComplianceComp(){
    return <div>
      <Navbar/>
      <section id="comp">
        <div className="container">
          <h1>Compliance</h1>
          <p id="justificar">No âmbito institucional e empresarial, a função do compliance é prevenir fraudes. Portanto, a equipe de Compliance Officer do Departamento de Compliance do Grupo Editora Alphaville elenca um conjunto de disciplinas a fim de cumprir e se fazer cumprir as normas legais e regulamentares, as políticas e as diretrizes estabelecidas para os seus negócios e atividades; evitando, detectando e tratando quaisquer desvios ou inconformidades que possam ocorrer. O objetivo é garantir que todas as práticas - internas e externas - desenvolvidas pela empresa estejam em conformidade com aquilo que a lei prevê, bem como os padrões e condutas internas. Desta forma, o compliance garante que o comportamento dos diferentes agentes da Editora Alphaville – que cultua valores como honra, disciplina, irmandade e ética, esteja em conformidade com as normas éticas estabelecidas, amparadas pela verdade e integridade em todos os relacionamentos.</p>

          <p id="justificar">  1.	Definições dos termos utilizados neste documento, bem como nos “Termos de Uso” e “Política de Privacidade” da Editora Alphaville:
                Usuário/Cliente: pessoa que visita a Plataforma e utiliza para, dependendo do grau de acesso, efetuar upload, criar, enviar mensagens ou receber, acessar, arquivar, apagar, efetuar download e gerenciar eventuais Documentos. 
                Informações: conjunto de dados e Documentos relativos a um Usuário. 
                Dado Pessoal: significa qualquer informação relativa a uma pessoa natural, identificada ou identificável. 
                Documento: arquivo carregado na Plataforma para ser assinado eletronicamente. 
                Plataforma: sistemas acessíveis por meio do site www.editoraalphaville.com.br, bem como eventuais aplicativos que poderão ser associados e administrados pela Editora Alphaville.  
                Signatário: pessoa que utiliza a Plataforma para assinar um Documento, se for o caso. 
                Usuário: Cliente, Signatário e demais pessoas que navegam na Plataforma. 

           <p/>  
           <p> 2.	Controle de acesso da Editora Alphaville as suas Informações: 
                  <p>a.	O Programa de Gestão de Segurança da Informação da Editora Alphaville inclui procedimentos de autenticação e controle para restringir o acesso dos colaboradores apenas às Informações necessárias para cumprimento de suas funções. O acesso às Informações seja realizado de acordo com os melhores padrões de segurança.</p>
                  <p>b.	Amazon Web Services: a Editora Alphaville poderá utilizar os serviços Amazon Web Services (AWS) para a sua infraestrutura, tais como Amazon Prime, KDP (Kindle Direct Publishing), EC2 (Amazon Elastic Compute Cloud), RDS (Amazon Relational Database Service), EKS (Elastic Kubernetes Service) e S3 (Amazon Simple Storage Service) com criptografia de alta performance, controle de versionamento de arquivos e níveis de permissões diversos para restringir acesso indevido às Informações, assim como outros serviços.</p>  
                  <p>c.	Firewalls e VPN: eventuais serviços da Editora Alphaville hospedados na internet, tais como servidores de aplicação e outros servidores que operam com o EKS (Elastic Kubernetes Services), estarão protegidos por firewall e WAF (Web Application firewall) nos data centers AWS. A Editora Alphaville faz uso de VPN – Virtual Private Network, rede particular virtual que criptografa o tráfego de internet e disfarça sua identidade online a fim de tornar segura a comunicação com nossos servidores, já que os servidores de base de dados, apartados, não são expostos via internet.</p> 
                  <p>d.	Segurança contra ataques: a Editora Alphaville utiliza medidas técnicas apropriadas para efetuar o endurecimento do sistema operacional, aplicar patches de segurança, utilizar sistemas de detecção de intrusos e fazer testes de penetração. No caso de prestação de serviços online, nosso ambiente de produção é programado para ser monitorado 24 horas por dia, 7 dias por semana, 365 dias por ano.</p> 
                  <p>e.	Testes de Penetração: de acordo com as melhores práticas, com o objetivo de avaliar a efetividade de medidas de segurança, a Editora Alphaville contrata periodicamente terceiros para realizar testes de penetração em sistemas internos e externos.</p>
           </p>
           <p> 3. Procedimentos de autenticação
                  <p>a. Cadastramento de Usuário: para maximizar a proteção e a confiança da Plataforma, como parte do processo de cadastramento ou demais usos dos serviços, a Editora Alphaville pode exigir que o Usuário, quer seja na qualidade de visitante quer seja na qualidade de cliente, se atenha às eventuais condições estabelecidas, de tempos em tempos e a seu critério, incluindo mas não se limitando ao fornecimento das Informações e/ou dos Dados Pessoais descritos nos Termos de Uso e sua Política de Privacidade. Ao submeter seus dados, o Usuário certifica-os como sendo atuais, completos e precisos.</p>
                  <p>b. Senha: ao contratar determinados serviços e efetuar ações correspondentes, o Usuário precisará de sua senha para fazer o login em sua conta. O Usuário é responsável por criar uma senha forte, mantê-la em sigilo, alterá-la periodicamente e não divulgar a ninguém, mesmo que sejam pessoas em quem ele confia – isso é essencial para auxiliar na prevenção de acesso não autorizado à conta e uso inadequado da Plataforma. A Editora Alphaville utiliza procedimentos para auxiliar na proteção, tais como Bcrypt e salted encryption (que adiciona uma cadeia aleatória de caracteres à senha antes dela ser criptografada).</p>
                  <p>c. Segurança contra ataques a contas: a Plataforma da Editora Alphaville prevê medidas para bloquear o acesso após tentativas seguidas de login com credenciais incorretas, feitos em um curto espaço de tempo. </p>
           </p>

           <p> 4. Segurança da Informação 
                 <p>a. Criptografia de arquivos: adotamos procedimentos para criptografar as Informações armazenadas no S3 da Amazon com algoritmo de cifra AES-256. As bases de dados da Editora Alphaville são criptografadas e a criptografia é programada para ocorrer no S3, antes das Informações serem salvas.</p>
                 <p>b. Criptografia de transmissões: a Editora Alphaville utiliza o Transport Layer Security (TLS) versão mínima 1.2 com validação estendida e criptografia de 256 bits para aumentar a proteção de Informações durante a transmissão por redes públicas. O TLS é um protocolo de segurança que garante a integridade de dados entre dois aplicativos de comunicação. Ele tem como objetivo garantir que a conexão entre o navegador, aplicativos e serviços da web seja segura e confiável. A Editora Alphaville realiza testes para confirmar o adequado funcionamento da Plataforma nas versões correntes dos browsers líderes de mercado. </p>
                 <p>c. Cartão de Crédito: a Editora Alphaville não processa nem armazena informações de cartão de crédito. O processamento e o armazenamento são realizados diretamente por prestadores de solução de pagamento com certificação PCI e contratados pela Editora Alphaville. </p>
           </p>

           <p> 5. Integridade 
                 <p>a.  Hashing: a Editora Alphaville adota procedimentos para utilizar o hashing – algoritmo SHA-256 – para proteção da integridade de Documentos, no caso de determinados serviços. A Editora Alphaville acredita que a verificação baseada em hashing assegura que um arquivo não foi alterado ao comparar o valor de hash do arquivo com um valor previamente calculado. Se houver a tentativa de fazer hashing de um Documento com apenas uma pequena diferença do original, o resultado do Hash SHA-256 será totalmente diferente.</p>
                 <p>b. Número de documento e Log: quando o Cliente faz upload de um Documento – como arquivos de originais, por exemplo, a Editora Alphaville adota procedimentos tanto para registrá-lo com um número único como para criar um Log registrando tal número. Dessa forma, o Documento e seu respectivo Log são logicamente associados. O Log é projetado para registrar assinaturas e outras informações relevantes, tais como hora e endereços de IP.</p>
                 <p>c. Registro de horário: todas as informações relativas a horário reportadas pela Plataforma são baseadas em relógios de servidor. A Editora Alphaville adota procedimentos para sincronizar seus relógios mediante o Network Time Protocol (NTP), um protocolo de sincronização confiável e de alta precisão.</p>
                 <p>d. Marca d’água: quando o Usuário faz upload de determinados Documento em alguns tipos de arquivos, como Microsoft Word ou PDF, a Editora Alphaville poderá adotar procedimentos para inserir o número único de Documento. Essa marca d’água servirá para ajudar a identificar a cópia impressa do Documento baixado da Editora Alphaville.</p>
           </p>

           <p> 6. Disponibilidade
                 <p>a. Backup: o backup da Editora Alphaville é criptografado, com separação entre o banco de dados e os arquivos, com redundância gerenciada pela Amazon.</p>
                 <p>b. Uptime: a Editora Alphaville mantém informações sobre disponibilidade, eventos e manutenção.</p>
                 <p>c. Caso fortuito e força maior: a Editora Alphaville não se responsabiliza por eventos além do seu controle, incluindo mas não se limitando a atrasos e falhas na prestação dos serviços que estejam relacionados a eventos como incêndios, falhas de telecomunicações, falhas decorrentes de atos de agentes públicos e ou assemelhados, falhas de energia, falhas de equipamento, conflitos trabalhistas, impedimentos temporários ou permanentes no sistema financeiro, rebeliões, guerras, ataques terroristas, inadimplemento de fornecedores ou força da natureza.</p>
                 <p>d. Gestão de Incidentes: para garantir a disponibilidade e qualidade dos serviços prestados, a Editora Alphaville possui políticas e procedimentos internos para gestão de incidentes e gerenciamento de crises. Esses processos e suas documentações atendem a legislação brasileira, são revisados periodicamente e são resguardados com o grau adequado de sigilo.</p>
           </p>

           <p> 7. Processamento e Retenção de Dados
                 <p>a. Localização dos servidores: a Editora Alphaville armazena e processa Informações em servidores da AWS localizados em São Paulo e na Virgínia do Norte, nos Estados Unidos. Para mais detalhes a respeito da transferência internacional de Dados Pessoais, por favor consulte a Política de Privacidade.</p>
                 <p>b. Download de Documentos: em caso de determinados serviços, a Editora Alphaville adota procedimentos para permitir que o Cliente efetue o download de Documentos na forma de arquivo PDF e Zip, via interface gráfica ou API, durante a vigência do contrato de prestação de serviços específicos entre a Editora Alphaville e o Cliente.</p>
                 <p>c. Mover um Documento para a “lixeira”: no caso da contratação de serviços específicos com acesso online à plataforma, se por alguma razão um Documento for movido para a “lixeira” por um Cliente, o administrador da conta Editora Alphaville, indicado pelo Cliente, poderá restaurá-lo a qualquer momento durante a vigência do contrato da prestação do respectivo serviço.</p>
                 <p>d. Exclusão: no caso da contratação de serviços específicos com acesso online à plataforma, a exclusão definitiva de determinado Documento, caso solicitada pelo Cliente, ocorrerá em duas etapas: (i) no prazo de 72 (setenta e duas) horas, o Documento será excluído das bases da Plataforma; (ii) ultrapassado o prazo previsto em (i), o Documento será excluído dos nossos backups após 30 (trinta) dias.</p>
                 <p>e. Retenção: a Editora Alphaville retém as Informações apenas na medida do necessário para (i) prestação dos serviços aos Usuários; (ii) cumprimento de obrigação legal ou regulatória pela Editora Alphaville e (iii) defesa da Editora Alphaville em processos administrativos, judiciais ou arbitrais.</p>
           </p>

          </p>


        </div>
       </section>


       <div className="row" id="planos">

       </div>
       <Complace/>
    </div>
  }

export default ComplianceComp;