import React from 'react';
import { Link } from 'react-router-dom';
import './compliance.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Compliance(){
    return <div>
      <Navbar/>
      <section id="comp">
        <div className="container">
          <h1>Compliance</h1>
          <p className='fw-normal'>Em desenvolvimento.</p>

          <Link to="/app/compliancecomp"><a></a></Link>


        </div>
       </section>


       <div className="row" id="planos">

       </div>
       <Complace/>
    </div>
  }

export default Compliance;