import React from 'react';
import { Link } from 'react-router-dom';
import './respsocialcomp.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function RespSocialComp(){
    return <div>
      <section id="comp">
        <div className="container">
        <p className='fs-1 fw-bold text-center'>RESPONSABILIDADE SOCIAL</p> 
          <p id="center">
            <p className='fw-normal'> Ações sociais da Editora Alphaville</p>
             <p className='fw-normal'> _____________ </p>

             <div className="row">
              <div className="col-lg-3">
                    <img src="/Images/01respsocial.png"/>
              </div>
              <div className="col-lg-6">
                  <p className='fw-normal'id="justificar"> Pensando e agindo de forma ética nas relações, o conceito do Grupo Editora Alphaville engloba ações voluntárias em benefício não apenas do seu público, mas da sociedade como um todo. Toda e qualquer editora tem poder para agir, mas a marca Editora Alphaville, pelos próprios preceitos históricos, carrega consigo a responsabilidade de transformar uma realidade.</p>
              </div>
              <div className="col-lg-3">
                    <img src="/Images/02respsocial.jpg"/>
              </div>
            </div>
            <p className='fw-normal'id="justificar">A agenda 2030 para o Desenvolvimento Sustentável, desenvolvida pela ONU, representa um plano de ações destinado ao planeta, as pessoas e à prosperidade. Ela sintetiza as aspirações e integra as dimensões econômica, social e ambiental, afirmando que, “para pôr o mundo em um caminho sustentável, é preciso tomar medidas ousadas e transformadoras”. Seu lema central, “Ninguém deixado para trás”, baseia-se em cinco princípios orientadores: Pessoas, Planeta, Prosperidade, Paz e Parcerias (05 Ps). </p>

            <div className="row">
              <div className="col-lg-3">

              </div>

              <div className="col-lg-6">
                <img src="/Images/04respsocial.jpg"/>
              </div>
              
              <div className="col-lg-3">

              </div>
            </div>




        </p>

          <p id="justificar">
          <p className='fw-normal'> É em respeito a esses princípios que, ao promover a transformação social, a Editora Alphaville executa as 05 atitudes citadas pela ONU na agenda 2030:</p>
          <p className='fw-normal'> 1.	Promove a responsabilidade social por meio de seus trabalhos na filantropia, ao implementar o Projeto Leitura Sustentável e Consciente a partir das ações sociais com o Sebo Takaoka e Jornal Folha Carapicuibana;</p>
          <p className='fw-normal'> 2.	Põe em prática os 5 R’s a fim de tomar as atitudes conscientes para o meio ambiente, promovendo a sustentabilidade: </p>
          <p className='fw-normal'> •	Repensar - consumindo materiais de forma consciente; </p>
          <p className='fw-normal'> •	Reduzir e Recusar - reduzindo e recusando o consumo desnecessário;</p>
          <p className='fw-normal'> •	Reutilizar e Reciclar - reutilizando e reciclando materiais nocivos ao meio ambiente. </p>
          <p className='fw-normal'> 3.	Incentiva os coletivos no país voltado à educação, promovendo grupos de estudo e pesquisa científica com pesquisadores da Universidade de São Paulo. </p>
          <p className='fw-normal'> 4.	Organiza campanhas diretas de doação de livros, além de campanhas de alimentos, agasalhos e outras necessidades para movimentos sociais na grande São Paulo. </p>
          <p className='fw-normal'> 5.	Inspira e mobiliza pessoas e empresas para que se engajem em ações sociais similares, em prol da sustentabilidade do país, buscando assim por uma transformação social efetiva. </p>

          <div className="row">
              <div className="col-lg-6">
                <img src="/Images/05respsocial.jpg"/>
              </div>
          </div>
          <p/>
          <p/>
          <p/>
          <p/>
          <p/>
          <p className='fs-3 fw-bold text-center'> Prestações de serviços e atitude sustentável da Editora Alphaville: uso da tecnologia em profundidade</p>
          
          
          <div className="row">
              <div className="col-lg-3">
                    <img src="/Images/06respsocial.jpg"/>
              </div>
              <div className="col-lg-9">
                  <p className='fw-normal'id="justificar"> A tecnologia pode desempenhar um papel importante na sustentabilidade e no meio ambiente de várias maneiras. Na qualidade de Startup, mas em caráter de parceria organizada em um grupo, a Editora Alphaville bem sabe que, quanto mais livros físicos são produzidos, mais bens naturais são utilizados. É certo que a maior parte de todo o papel fabricado no Brasil advém de madeira de reflorestamento como forma de minimizar o prejuízo ambiental – que é causado sobretudo por conta do desmatamento e do desgaste dos recursos naturais. Ainda assim, há um grande problema: para a produção do papel, além do uso de produtos químicos e de energia, gasta-se uma quantidade absurda de água!</p>
              </div>
            </div>
          
          
            <div className="row">
              <div className="col-lg-9">
                  <p className='fw-normal'id="justificar">De acordo com os pesquisadores da Editora Alphaville e do Instituto Akatu, cada tronco de eucalipto produz 10 mil folhas A4, de 75g/m², mas para a produção de cada folha são utilizados 10 litros de água. Logo, para as 10 mil folhas, é necessário100.000 litros de água ou 100 metros cúbicos (cada metro cúbico corresponde a mil litros de água). </p>
              </div>
              <div className="col-lg-3">
                    <img src="/Images/07respsocial.jpg"/>
              </div>
            </div>
          
          
            <div className="row">
              <div className="col-lg-3">
                    <img src="/Images/08respsocial.jpg"/>
              </div>
              <div className="col-lg-9">
              <p className='fw-normal'id="justificar"> Considerando um livro com peso médio de 400 gramas, a produção de 125 livros necessita de 50 quilos de papel, o equivalente a uma árvore. A tiragem média de um livro, quando feita diretamente pela Editora Alphaville, bem como por qualquer outra editora no Brasil - sem parcerias estrangeiras -, é de 3 mil exemplares – número mínimo exigido para a viabilidade do trabalho gráfico brasileiro. Desta forma, 24 árvores serão utilizadas para a produção de um único título! </p>
              </div>
            </div>
         
         
          <p className='fw-normal'> Por esta razão, como responsabilidade social a Editora Alphaville implementou o Projeto Leitura Sustentável e Consciente, que diz respeito a duas ações:</p>
         
          <div className="row">
              <div className="col-lg-3">
                    <img src="/Images/10respsocial.jpg"/>
              </div>
              <div className="col-lg-6">
              <p className='fw-normal'id="justificar">  1.	Sebo Takaoka: o Projeto Leitura Sustentável e Consciente oferece uma série de títulos usados e muito bem conservados, com preços justos a fim de manter a acessibilidade da ação “Roda da Leitura” no Sebo Takaoka, bem como o “jornal Folha Carapicuibana” na qualidade de ação social principal, disseminadora de direitos, deveres, educação e cultura à coletividade. Os livros usados e muito bem conservados são na maior parte comprados pela Editora Alphaville ou podem, ainda, serem doados à editora com a finalidade de manter tais projetos. Ajude a promover a ação “Roda da Leitura” fazendo parte do Projeto Leitura Sustentável e Consciente: faça a doação do seu livro, clicando aqui.</p>
              </div>
              <div className="col-lg-3">
                    <img src="/Images/09respsocial.jpg"/>
              </div>
            </div>
         
          <p className='fw-normal'> 2.	Jornal Folha Carapicuibana: o Projeto Leitura Sustentável e Consciente conscientiza os leitores da grande São Paulo a lerem as edições eletrônicas do jornal Folha Carapicuibana em vez de optar pelas edições impressas que, após lidos, acabam descartados no lixo. Conforme esclarecido, mesmo havendo programas de reciclagem, muitas árvores foram destruídas para a produção do papel, além do desgaste dos recursos naturais. Comparado com a edição impressa, além da leitura sustentável e consciente, a grande vantagem da edição online do jornal é poder acessar todas as matérias e artigos por meio da busca, pois todos os arquivos ficam armazenados desde a primeira edição do jornal. Enquanto que no impresso a única possibilidade existente é a de recortar uma notícia enquanto aquela edição ainda está em mãos, no eletrônico basta ir na busca e digitar o tema ou algo relacionado ao assunto ou, ainda, colocar uma palavra chave.  A empresa de edição do jornal Folha Carapicuibana foi aberta dia 01/08/2012 na grande São Paulo e, desde 2015, é marca própria dos editores chefes com a concessão da marca Folha e de responsabilidade social da Editora Alphaville. Acesse o jornal:
          <a href="www.folhacarapicuibana.com.br"> clique aqui...</a> </p>
          
          
          <p className='fw-normal'> **Além das duas ações promovidas pelo Projeto Leitura Sustentável e Consciente, a Editora Alphaville adotou o seu próprio Conjunto de Ações Econômicas e Racionais - direcionadas tanto as empresas quanto as pessoas físicas - a fim de minimizar os impactos causados pelo uso do papel. São elas:</p>
          <p className='fw-normal'> 1.	Na comunicação, prefira enviar por meio de mensagens eletrônicas as notificações, orçamentos e quaisquer tipos de comunicado. O procedimento tem validade jurídica e pode ser feito por e-mails, WhatsApp, Microsoft Teams etc. </p>
          <p className='fw-normal'> 2.	No envio e recebimento de documentos, dê preferência aos eletrônicos.</p>
          <p className='fw-normal'> 3.	Para documentos que precisam ser assinados pelas partes – como termos e contratos -, prefira a assinatura eletrônica, pois há validade jurídica garantida pela Medida Provisória 2.200-2: esta MP regulamenta o uso de assinatura digital e eletrônica no Brasil para formalizar qualquer documento. Com validade jurídica, a Editora Alphaville oferece os serviços de Assinatura Eletrônica em Assessoria Empresarial para o meio Imobiliário. Com preços justos, as empresas no mercado digital que trabalham com assinatura eletrônica para seus documentos oferecem planos ideais, podendo ser individual ou pacotes mensais de assinatura, de acordo com suas demandas. </p>
          <p className='fw-normal'> 4.	Quanto a outros documentos e materiais impressos, imprima tão somente aquilo que for necessário. Antes de imprimir, faça uma revisão do material para não correr o risco de ter que imprimi-los novamente. </p>
          <p className='fw-normal'> 5.	Ao descartar papeis que foram impressos de um lado apenas, faça um bloco de rascunho do lado oposto. </p>
          <p className='fw-normal'> 6.	Aos empreendedores, em vez de utilizar papéis e blocos de notas para fazer o controle dos serviços prestados ou das vendas dos produtos oferecidos em seu estabelecimento comercial, prefira os aplicativos que, além de inserir a sua empresa no panorama de ações sustentáveis, otimizará tempo e ganhos, vendendo mais e fazendo menos. O Grupo Editora Alphaville com sua startup de Tecnologia, key42, no desenvolvimento de soluções inteligentes como parte de suas ações econômicas e racionais, oferece aplicativos por assinaturas com todas as vantagens e valores extremamente acessíveis para a sua empresa – incluindo aplicativos de vistorias técnicas para imóveis, sendo a mais alta tecnologia para o mercado imobiliário. Saiba mais.</p>
          <p className='fw-normal'> 7.	Quando a impressão for necessária, opte por imprimir frente e verso.</p>
          <p className='fw-normal'> </p>

          </p>
          
        </div>
       </section>

       <div className="row" id="planos">

       </div>
       <Complace/>
    </div>
  }

export default RespSocialComp;