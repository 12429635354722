import React from 'react';
import { Link } from 'react-router-dom';
import './respsocial.css';

import Navbar from '../Components/Navbar/navbar.jsx';
import Complace from '../Components/Complace/complace.jsx';

function Respsocial(){
    return <div>

      <section id="comp">
        <div className="container">
        <p className='fs-1 fw-bold text-center'>RESPONSABILIDADE SOCIAL</p> 
          <p id="center">
          <p className='fw-normal'> Ações sociais da Editora Alphaville</p>
              <img src="/Images/13respsocial.jpg"/>
          <p className='fw-normal'> ____________ </p>
  
            <div className="row">
              <div className="col-lg-3">
                    <img src="/Images/01respsocial.png"/>
              </div>
              <div className="col-lg-9">
                  <p className='fw-normal'id="justificar"> Pensando e agindo de forma ética nas relações, o conceito do Grupo Editora Alphaville engloba ações voluntárias em benefício não apenas do seu público, mas da sociedade como um todo. Toda e qualquer editora tem poder para agir, mas a marca Editora Alphaville, pelos próprios preceitos históricos, carrega consigo a responsabilidade de transformar uma realidade.</p>
              </div>
            </div>

          <p className='fw-normal'> _____________ </p>

          <Link to="/app/respsocialcomp"><a>Ler mais...</a></Link>
          </p>
          
        </div>
       </section>

       <div className="row" id="planos">

       </div>
       <Complace/>
    </div>
  }

export default Respsocial;